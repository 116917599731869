import React from 'react';
import { Button, Form, Input, InputNumber, message } from 'antd';
import axios from 'axios';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 32 },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
};
const onFinish = async (values) => {
    const body = {
        'FromEmail': 'srinivas@urbanplan.in',
        'FromName': 'Urban Plan',
        'Subject': 'Request a Quote',
        'Text-part': 'Details as below: Name:' + values.name + ', Email: ' + values.email + ', Phone: ' + values.phone + ', Message: ' + values.message,
        'Html-part': 'Details as below: Name:' + values.name + ', Email: ' + values.email + ', Phone: ' + values.phone + ', Message: ' + values.message,
        'Recipients': [
            {
                'Email': 'vl.pradeepkumar@gmail.com'
            }
        ]
    };
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
    const auth = {
        "username": "e28c76aa0650b6cfb8ae9f6d606e99ba",
        "password": "4dd93cffda77ac9bb52c82ec7c09027b"
    }
    console.log(body, headers);
    try {
        // Assuming your API endpoint is as follows
        const response = await axios.post('https://api.mailjet.com/v3/send', body, headers, auth);
        console.log('Success:', response.data);
        //form.resetFields();
        message.success(response.data.msg)
        // Handle success (e.g., show a success message)
    } catch (error) {
        console.error('Error:', error);
        message.error("error found")
        //form.resetFields();
        // Handle error (e.g., show an error message)
    }
};
const ContactUs = () => (
    //const [form] = Form.useForm();

  <Form
    {...layout}
    name="nest-messages"
    onFinish={onFinish}
    validateMessages={validateMessages}
  >
    <Form.Item name={['name']} label="Name" rules={[{ required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item name={['email']} label="Email" rules={[{ required: true, type: 'email' }]}>
      <Input />
    </Form.Item>
    <Form.Item name={['phone']} label="Phone Number">
      <Input />
    </Form.Item>
    <Form.Item name={['message']} label="Message">
      <Input.TextArea />
    </Form.Item>
    <Form.Item label={null}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);
export default ContactUs;