import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

export default function TabContent({ title, images }) {
    return (
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {images.map((src, index) => (
                <SwiperSlide key={index + 1}>
                    <img
                        src={src}
                        alt={`${title} ${index + 1}`}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}