import React, { createRef, useState, useEffect } from 'react';
import { Layout, Menu, message, Card, Col, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import TabContent from './TabContent.jsx'
import ContactUs from './ContactUs.jsx'

const { Header, Content, Footer } = Layout;
const jsonContent = require('../data/data.json');
const showPromo = jsonContent.showPromo;


const UrbanPlan = () => {
    const packagesRef = createRef(null);
    const servicesRef = createRef(null);
    const galleryRef = createRef(null);
    const homeRef = createRef(null);
    const projectRef = createRef(null);
    const contactRef = createRef(null);
  
    const scrollToServices = () => {
        if (servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const scrollToPackages = () => {
        if (packagesRef.current) {
            packagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const scrollToGalleryRef = () => {
        if (galleryRef.current) {
            galleryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const scrollTohomeRef = () => {
        if (homeRef.current) {
            homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollToProjectRef = () => {
        if (projectRef.current) {
            projectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const scrollToContactRef = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const [selectedTab, setSelectedTab] = useState('Master Bedroom');

    return (
            <Layout style={{ overflowX: "hidden" }}>
                <Header className="custom-header">
                    <div className="logo-container">
                        <img src="logo.webp" alt="Logo" className="logo" style={{ height: "45px", width: "100%" }} />
                    </div>
                    <div className="menu-container">
                        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                            <Menu.Item key="1" onClick={scrollTohomeRef}>Home</Menu.Item>
                            <Menu.Item key="2" onClick={scrollToPackages}>Package</Menu.Item>
                            <Menu.Item key="3" onClick={scrollToServices}>Services</Menu.Item>
                            <Menu.Item key="4" onClick={scrollToGalleryRef}>Gallery</Menu.Item>
                            <Menu.Item key="5" onClick={scrollToProjectRef}>Projects</Menu.Item>
                        </Menu>
                    </div>
                </Header>
                <Content className="main-container" ref={homeRef}>
                    <div className="main-title"><h1>{jsonContent.title}</h1></div>
                    <div className="main-subtitle">{jsonContent.subTitle}</div>
                    <div className="space-between"></div>

                    {/* Image Section */}
                    <div className="herobanner maxwidth">
                        <img
                            src={jsonContent.heroBanner.image}
                            alt={jsonContent.heroBanner.alt}
                        />
                        <div className="about">
                            <span>{jsonContent.heroBanner.desc.title}</span>
                            <p>{jsonContent.heroBanner.desc.body}</p>
                        </div>
                    </div>
                    <div className="space-between"></div>
                   
                    <div className="heading-container">
                        <span className="main-p-head">{jsonContent.welcome_note}</span>
                    </div>

                    {/* Promo Section */}
                    {
                        showPromo.status ? 
                            <section className="home-p-section3" style={{ marginTop: "40px" }}>
                                <div className="section3-container" style={{ position: "relative" }}>
                                    <div className="section3-image-container">
                                        <img
                                            src={showPromo.image.src}
                                            alt={showPromo.image.alt}
                                            className="section3-responsive-image"
                                        />
                                    </div>
                                    <div className="section3-text-box" >
                                        <h4 style={{marginTop:"50px"}} className="main-p-head">{showPromo.label}</h4>
                                        <div className="main-p-text">
                                            <p style={{ fontSize: "18px", width: "300px" }} >{showPromo.description}</p>
                                        </div>
                                        <div className="main-p-button">
                                            <button  onClick={scrollToContactRef} className="btn" style={{ marginTop: "15px" }}>Get Detailed Specification</button>
                                        </div>
                                        <div style={{marginTop:"100px"}}> <p style={{fontSize:"10px"}}>Terms & Conditions applied</p></div>
                                    </div>
                                    
                                </div>
                            </section>
                        : null
                    }

                    {/* Packages Section */}
                    <div>
                        <div ref={packagesRef} className="section-title"><span>Packages</span></div>
                        <div className="section-packages">
                            <Row gutter={16} style={{ margin: "20px" }}>
                                {jsonContent.packages.map((package_item, pac_index) => (
                                    <Col xs={24} sm={24} md={24} lg={24} xl={6} key={pac_index}>
                                        <Card title={package_item.title} bordered={true} className='packages' key={pac_index}>
                                            <div className="cost">
                                                Starts From: {package_item.cost}<span style={{ marginBottom: "10px" }}>*</span>
                                            </div>
                                            <div className="services">
                                                {package_item.services.map((service, ser_index) => (
                                                    <Row gutter={16} key={ser_index}>
                                                        <div className="services_title">
                                                            <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                            {service.title}
                                                        </div>
                                                        <div className="services_options">
                                                            {service.options.map((option, option_index) => (
                                                                <div key={option_index}>{option}</div>
                                                            ))}
                                                        </div>
                                                    </Row>
                                                ))}
                                            </div>
                                            <button onClick={scrollToContactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                    <div className="space-between"></div>

                    {/* Services Section */}
                    <section className="services maxwidth">
                        <div ref={servicesRef} className="section-title"><span>{jsonContent.services.title}</span></div>
                        <div className="section-body">{jsonContent.services.body}</div>
                        <div className="space-between"></div>

                        {jsonContent.services.sections.map((section_item, sec_index) => (
                            <div className="home-p-sections" key={sec_index}>
                                <div className="RC-div" >
                                    <div className="RC-img-div">
                                        <img
                                            src={section_item.image.src}
                                            alt={section_item.image.src}
                                            className="RC-img"
                                        />
                                    </div>
                                    <div className="RC-txt-div">
                                        <div className="main-p-heading1">{section_item.title}</div>
                                        <ul>
                                            {section_item.body.map((desc_item, desc_index) => (
                                                <li key={desc_index}>
                                                    <span className="label">{desc_item.label} </span>
                                                    <span>{desc_item.body}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section>

                    {/* Gallery Section */}
                    <div>
                        <div ref={galleryRef} className="section-title bottom-nil"><span>{jsonContent.photos.title}</span></div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                {jsonContent.photos.tabs.map((tab, tab_index) => (
                                    <div
                                        onClick={() => setSelectedTab(tab.title)}
                                        key={tab_index}
                                        style={{
                                            margin: '0 10px',
                                            borderBottom: selectedTab === tab.title ? '2px solid blue' : 'none',
                                            padding: '10px 20px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {tab.title}
                                    </div>
                                ))}
                            </div>
                            <div className="web-page">
                                {selectedTab === 'Master Bedroom' && (
                                    <TabContent
                                        title={selectedTab}
                                        images={jsonContent.photos.tabs[0].images}
                                    />
                                )}
                                {selectedTab === 'Kids Bedroom' && (
                                    <TabContent
                                        title={selectedTab}
                                        images={jsonContent.photos.tabs[1].images}
                                    />
                                )}
                                {selectedTab === 'Common Area' && (
                                    <TabContent
                                        title={selectedTab}
                                        images={jsonContent.photos.tabs[2].images}
                                    />
                                )}
                            </div>
                            <div className="mobile-page"  >
                                {selectedTab === 'Master Bedroom' && (
                                      jsonContent.photos.tabs[0].images.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`${jsonContent.photos.tabs[0].title} ${index + 1}`}
                                            style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                        />
                                    ))
                                )}
                                {selectedTab === 'Kids Bedroom' && (
                                   
                                        // title="Kid Bedroom"
                                        jsonContent.photos.tabs[1].images.map((src, index) => (
                                            <img
                                                key={index}
                                                src={src}
                                                alt={`${jsonContent.photos.tabs[1].title} ${index + 1}`}
                                                style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                            />
                                        ))
                                  
                                )}
                                {selectedTab === 'Common Area' && (
                                     jsonContent.photos.tabs[2].images.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`${jsonContent.photos.tabs[2].title} ${index + 1}`}
                                            style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    
                    {/* Quote Section */}
                    {jsonContent.quote.status ? 
                    <div className="up-quote">
                        <div className="space-between"></div>
                        <img
                            src={jsonContent.quote.image.src}
                            alt={jsonContent.quote.image.alt}
                            className="responsive-image" // Use a class for better control
                        />
                        <div className="quote"><span>{jsonContent.quote.text}</span></div>
                    </div>
                    : null
                    }

                    {/* Projects Section */}
                    <section className="projects maxwidth">
                        <div ref={projectRef} className="section-title"><span>{jsonContent.projects.title}</span></div>
                        <div>
                            <div className="project-content">
                                <div className="project-wrapper">
                                    {jsonContent.projects.images.map((src, index) => (
                                        <div className="project" key={index}>
                                            <img
                                                src={src}
                                                alt={`House ${index + 1}`}
                                                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Contact Us Form*/}
                    <div className="divider" />
                    <section className="contactus maxwidth">
                        <div ref={contactRef}>
                            <div className="contactus-wrapper">
                                <div className="contactus-logo">
                                    <img src="/UrbanPlan.png" alt="Urban Plan" />
                                </div>
                                <div className="contactus-form">
                                    <div className="message">Request a Quote.</div>
                                    <ContactUs></ContactUs>
                                </div>
                            </div>
                        </div>
                    </section>


                </Content>
                <Footer className="footer">Urban Plan @2025</Footer>
            </Layout>
    )
}
export default UrbanPlan