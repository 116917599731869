import './App.css';
import UrbanPlan from './components/UrbanPlan.jsx';


function App() {
  return (
    <UrbanPlan/>
  );
}

export default App;
